import React from "react";

import Flex from "../../Flex";

export default function Content({ sx, children, ...otherProps }) {
  return (
    <Flex
      sx={{
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        pb: "60px",
        ...sx,
      }}
    >
      {children}
    </Flex>
  );
}
