import React from "react";

import Box from "../../Box";

import { fadeInAnim } from "../../../../utils/styles";

export default function Illustration({ children, sx, ...otherProps }) {
  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        ml: "auto",
        flex: 1,
        maxWidth: "30%",
        maxHeight: "100%",
        ...fadeInAnim,
        img: {
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          height: "90%",
          margin: "auto",
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
}
