import React from "react";
import { useInView } from "react-intersection-observer";

import Box from "../Box";
import { cover, fadeIn } from "../../../utils/styles";

let config = {
  SIM_RESOLUTION: 128,
  DYE_RESOLUTION: 512,
  CAPTURE_RESOLUTION: 256,
  DENSITY_DISSIPATION: 0.6,
  VELOCITY_DISSIPATION: 2.2,
  PRESSURE: 0,
  PRESSURE_ITERATIONS: 20,
  CURL: 1,
  SPLAT_RADIUS: 0.8,
  SPLAT_FORCE: 6000,
  SHADING: true,
  COLOR_UPDATE_SPEED: 0.6,
  BACK_COLOR: { r: 0, g: 0, b: 0, a: 1 },
  BLOOM: true,
  BLOOM_ITERATIONS: 3,
  BLOOM_RESOLUTION: 256,
  BLOOM_INTENSITY: 0.3,
  BLOOM_THRESHOLD: 0.8,
  BLOOM_SOFT_KNEE: 0.9,
  SUNRAYS: true,
  SUNRAYS_RESOLUTION: 196,
  SUNRAYS_WEIGHT: 1.0,
  TRANSPARENT: true,
};

const SpaceAnim = ({ colors = [], sx, ...otherProps }) => {
  const [startAnimate, setAnimate] = React.useState(false);
  const canvasElRef = React.useRef();
  const animRef = React.useRef();
  const [ref, isInView] = useInView({
    rootMargin: `20% 0px`,
  });

  React.useEffect(() => {
    const canvas = canvasElRef.current;

    import("./FluidSimulation").then((module) => {
      const FluidSimulation = module.default;

      setTimeout(() => {
        animRef.current = new FluidSimulation(canvas, config);
        setAnimate(true);
      }, 1000);
    });

    return () => {
      animRef.current && animRef.current.dismount();
    };
  }, []);

  React.useEffect(() => {
    if (!animRef.current) return;

    if (isInView) {
      animRef.current.play();
    } else {
      animRef.current.pause();
    }
  }, [isInView]);

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        ...cover,
        background: "black",
        ...sx,
      }}
    >
      <Box
        sx={{
          ...cover,
          background: "url(/space-tile-2.jpg) repeat",
          backgroundSize: "60%",
          animation: startAnimate ? `${fadeIn} 2s 1s ease-out both` : "",
          opacity: 0,
        }}
      />
      <Box
        ref={canvasElRef}
        as="canvas"
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          animation: startAnimate ? `${fadeIn} 1s 1s ease-out both` : "",
          opacity: 0,
        }}
      />
    </Box>
  );
};

export default SpaceAnim;
