import React from "react";

import Text from "../../Text";
import { blurOut, fadeIn } from "../../../../utils/styles";

export default function Subtitle({ animate, sx, children, ...otherProps }) {
  const [startAnimate, setAnimate] = React.useState(false);

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <Text
      as="h2"
      sx={(theme) => ({
        position: "relative",
        zIndex: 1,
        color: "inverted",
        width: [220, 450],
        maxWidth: [null, "40%"],
        mt: [3, 4],
        ...(animate &&
          startAnimate && {
            animation: `${blurOut} 1s 3.2s ease-out both`,
            // disable blur animation on Firefox as it's slow
            "@supports (-moz-appearance:none)": {
              animation: `${fadeIn} 1s 3.2s ease-out both`,
            },
          }),
        ...(animate && {
          opacity: 0,
        }),
        ...sx,
      })}
    >
      {children}
    </Text>
  );
}
