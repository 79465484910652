import React from "react";

import Header from "../Header";
import Box from "../Box";
import GridRow from "../GridRow";

import Background from "./Background";
import Title from "./Title";
import Content from "./Content";
import Subtitle from "./Subtitle";
import Illustration from "./Illustration";

import { gradientBg } from "../../../utils/styles";

const Hero = ({ fullscreen, animate, sx, children, ...otherProps }) => {
  return (
    <Box
      className="hero-container"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        ...gradientBg,
        ...(fullscreen
          ? {
              height: ["130vw", "100vh"],
              maxHeight: "100vh",
            }
          : {
              height: ["90vw", "55vh"],
            }),
        ...sx,
      }}
    >
      <Header animateIn={animate} />
      <GridRow sx={{ flexGrow: 1, ...sx }}>
        <GridRow.Col sx={{ display: "flex", alignItems: "center" }}>
          {children}
        </GridRow.Col>
      </GridRow>
    </Box>
  );
};

export default Hero;

Hero.Background = Background;
Hero.Title = Title;
Hero.Subtitle = Subtitle;
Hero.Illustration = Illustration;
Hero.Content = Content;
