import React from "react";

import Heading from "../../Heading";
import Box from "../../Box";
import { blurOut, fadeIn } from "../../../../utils/styles";

const RawTitle = React.forwardRef(
  ({ animateText, sx, children, ...otherProps }, ref) => {
    return (
      <Heading
        ref={ref}
        as="h1"
        variant="h1"
        sx={(theme) => ({
          ml: "-0.05em",
          mb: -3,
          whiteSpace: "pre-line",
          color: "inverted",
          fontSize: "34px",
          "&[data-splitting]": {
            visibility: "hidden",
            "&.splitting": {
              visibility: "visible",
            },
          },
          ".word": {
            animation: `${blurOut} 1s ease-out both`,
            // disable blur animation on Firefox as it's slow
            "@supports (-moz-appearance:none)": {
              animation: `${fadeIn} 2s ease-out both`,
              animationDelay:
                "calc(2000ms + 500ms * (1 + var(--word-index) / 5))",
            },
            animationDelay:
              "calc(1300ms + 1000ms * (1 + var(--word-index) / 5))",
          },
          ...sx,
        })}
        {...(animateText && {
          "data-splitting": "words",
        })}
        {...otherProps}
      >
        {children}
      </Heading>
    );
  }
);

export default function Title({ animateText = "", sx, ...otherProps }) {
  const elRef = React.useRef();
  const innerHTML = {
    __html: animateText.replace(/\n/g, "<br />"),
  };

  React.useEffect(() => {
    if (!animateText) return;

    // Splitting.js is preloaded for performances optimisation. See gatsby-browser.js
    if (!window.Splitting) {
      import("splitting/dist/splitting-lite").then(({ default: Splitting }) => {
        Splitting({ target: elRef.current, by: "words" });
      });
    } else {
      window.Splitting({ target: elRef.current, by: "words" });
    }
  }, [animateText]);

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      {animateText ? (
        <RawTitle
          ref={elRef}
          animateText
          dangerouslySetInnerHTML={innerHTML}
          {...otherProps}
        />
      ) : (
        <RawTitle {...otherProps} />
      )}
    </Box>
  );
}
