import React from "react";

import Box from "../../Box";
import { cover } from "../../../../utils/styles";

export default function Background({ node, sx, children, ...otherProps }) {
  return (
    <Box
      sx={{
        ...cover,
        overflow: "hidden",
        backgroundColor: "background",
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
}
